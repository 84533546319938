<template>
  <div>
      <div class="h-96 w-full">
          <img class="w-full h-full object-cover" loading="lazy" :src="require(`@/assets/images/${imgURL}`)" :alt="name" />
      </div>
    <h5>{{ title }}</h5>
    <div class="flex space-x-4 mt-4">
        <a>
            <LinkedIn />
        </a>
        <a>
            <Twitter />
        </a>
    </div>
    <p class="text-xl text-grey-350 mt-4">
      {{ description }}
    </p>
  </div>
</template>

<script>
import Twitter from '../../../public/svg/twitter-brown.svg';
import LinkedIn from '../../../public/svg/linkedin-brown.svg'
export default {
  name: "TeamMember",
  components: {
      Twitter,
      LinkedIn
  },
  props: {
      title: {
          type: String,
          required: true
      },
      description: {
          type: String,
          required: true
      },
      imgURL: {
          type: String,
          required: true
      },
  },
};
</script>

<style lang="scss" scoped>
</style>