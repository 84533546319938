<template>
  <div>
    <Container class="bg-accent pb-10 relative">
      <Navbar />
      <div class="w-6/12 mt-24">
        <h1>About Ydev</h1>
        <div class="flex flex-col">
          <p class="text-grey-350 text-xl leading-8 mt-6">
            Ydev Academy is to equip our students with the necessary digital and
            future skills to nurture confident, competent and imaginative
            professionals brave enough to pursue their ambitions.
          </p>
        </div>
      </div>
      <div class="w-5/12 mt-24">
        <h5>We have partnered with organisations across various industries</h5>
      </div>
      <div class="w-full flex space-x-10 items-center mt-8">
        <Google />
        <Segment />
        <Amazon />
        <DigitalOcean />
      </div>
      <div class="relative -bottom-56 -mt-32">
        <img
          src="@/assets/images/about-us.png"
          alt="about us"
          loading="lazy"
          class="h-full w-full"
        />
      </div>
    </Container>
    <Container class="bg-white">
      <div class="grid grid-cols-2 gap-x-14 my-64">
        <div>
          <Mission />
          <h5 class="text-3xl mt-6">Our Mission</h5>
          <p class="text-xl text-grey-350 mt-6">
            To help Africans reach their highest potential through a world class
            education.
          </p>
        </div>
        <div>
          <Vision />
          <h5 class="text-3xl mt-6">Our Vision</h5>
          <p class="text-xl text-grey-350 mt-6">
            We are empowering the African workforce by teaching in-demand tech
            skills to help people capture global opportunities.
          </p>
        </div>
      </div>
      <div class="w-8/12">
        <h5 class="text-4xl">Our Story</h5>
        <p class="text-xl text-grey-350 mt-10">
          Product design starts with understanding the needs of users, which is
          gathered through comprehensive research. Learn the core principles of
          human-centered design and how to appropriately scope a design problem.
          Understand how to empathize with users when performing user research,
          including how to conduct in-depth interviews and create quantitative
          surveys, and use research data to uncover opportunities. You’ll then
          apply psychology to design sketches, keeping the end-user in mind.
        </p>
        <div class="flex flex-row my-10 space-x-6">
          <div class="btn btn--primary">Explore all programs</div>
          <div class="btn btn--outline px-10">Learn More</div>
        </div>
      </div>
      <div class="mb-14">
        <div class="grid grid-cols-2 gap-x-8">
          <div class="h-500">
            <img
              class="rounded h-full w-full object-cover"
              loading="lazy"
              src="@/assets/images/our-image-1.png"
              alt="our people"
            />
          </div>
          <div class="h-500">
            <img
              class="rounded h-full w-full object-cover"
              loading="lazy"
              src="@/assets/images/our-image.png"
              alt="our people"
            />
          </div>
        </div>
        <div class="mt-8">
          <img
            src="@/assets/images/our-image-3.png"
            loading="lazy"
            class="rounded h-full w-full object-cover"
            alt=" our people"
          />
        </div>
      </div>
    </Container>
    <Container class="bg-smoke pt-28 pb-44">
      <div class="w-4/6">
        <h1>The journey so far and our collective achievements</h1>
        <p class="text-xl text-grey-350 mt-4">
          We are uniquely positioned to capitalize on this tremendous
          opportunity to deliver cutting-edge and culturally competent software
          to financial institutions.
        </p>
      </div>
      <div>
        <div class="grid grid-cols-4 gap-y-20 mt-20">
          <PerformanceItem
            v-for="performance of performances"
            :key="performance.title"
            :metric="performance.metric"
            :icon="performance.icon"
            :title="performance.title"
          />
        </div>
      </div>
    </Container>
    <Container class="bg-white pt-28 pb-20 ">
      <div class="w-3/5 mx-auto mb-28">
        <h1>A leadership team with vision</h1>
        <p class="text-xl text-grey-350 text-center mt-4">
          We are uniquely positioned to capitalize on this tremendous
          opportunity to deliver cutting-edge and culturally competent software
          to financial institutions.
        </p>
      </div>
      <div>
        <div class="grid grid-cols-2 gap-x-10 gap-y-16">
          <TeamMember
            v-for="member in members"
            :key="member.name"
            :name="member.name"
            :description="member.description"
            :imgURL="member.imgURL"
          />
        </div>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/blocks/Navbar.vue";
import Container from "@/components/blocks/Container";
import Google from "../../public/svg/google.svg";
import Segment from "../../public/svg/segment.svg";
import Amazon from "../../public/svg/amazon.svg";
import DigitalOcean from "../../public/svg/digital-ocean.svg";
import Footer from "@/components/blocks/Footer";
import Mission from "../../public/svg/mission.svg";
import Vision from "../../public/svg/vision.svg";
import PerformanceItem from "@/components/blocks/PerformanceItem";
import TeamMember from '@/components/blocks/TeamMember';

export default {
  name: "About",
  components: {
    Navbar,
    Container,
    Google,
    Segment,
    Amazon,
    DigitalOcean,
    Vision,
    Mission,
    Footer,
    PerformanceItem,
    TeamMember
  },
  data() {
    return {
      performances: [
        {
          metric: "1,200+",
          title: "Learning Hours",
          icon: "Education",
        },
        {
          metric: "80%",
          title: "Completion Rates",
          icon: "Learn",
        },
        {
          metric: "92%",
          title: "Courses Rating",
          icon: "CourseRating",
        },
        {
          metric: "11+",
          title: "Countries",
          icon: "Globe",
        },
        {
          metric: "3,500+",
          title: "Student Trained",
          icon: "Student",
        },
        {
          metric: "500+",
          title: "Total Alumni ",
          icon: "Alumni",
        },
        {
          metric: "20+",
          title: "Daily Sessions",
          icon: "Sessions",
        },
      ],
      members: [
        {
          name: 'Biyi Adebayo',
          description: 'Biyi is a Data Scientist with a degree in engineering. He is interested in utilizing data for solving problems. In his toolbox are languages and softwares like Python, SQL, R, PowerBI and Tableau. He has taken on a few Machine Learning projects. He had brief stints in the automobile and education sector before venturing into Tech. He is a firm believer in out-of-school learning and he considers technology a vital tool for delivering effective education. He relishes every opportunity to teach.',
          imgURL: 'person.png',
          linkdinURL: '',
          twitterURL: ''
        },
        {
          name: 'Lotachukwu Ibe',
          description: 'Biyi is a Data Scientist with a degree in engineering. He is interested in utilizing data for solving problems. In his toolbox are languages and softwares like Python, SQL, R, PowerBI and Tableau. He has taken on a few Machine Learning projects. He had brief stints in the automobile and education sector before venturing into Tech. He is a firm believer in out-of-school learning and he considers technology a vital tool for delivering effective education. He relishes every opportunity to teach.',
          imgURL: 'person.png',
          linkdinURL: '',
          twitterURL: ''
        },
        {
          name: 'Olayemi Abimbola',
          description: 'Biyi is a Data Scientist with a degree in engineering. He is interested in utilizing data for solving problems. In his toolbox are languages and softwares like Python, SQL, R, PowerBI and Tableau. He has taken on a few Machine Learning projects. He had brief stints in the automobile and education sector before venturing into Tech. He is a firm believer in out-of-school learning and he considers technology a vital tool for delivering effective education. He relishes every opportunity to teach.',
          imgURL: 'person.png',
          linkdinURL: '',
          twitterURL: ''
        },
        {
          name: 'Bernard O’Bien',
          description: 'Biyi is a Data Scientist with a degree in engineering. He is interested in utilizing data for solving problems. In his toolbox are languages and softwares like Python, SQL, R, PowerBI and Tableau. He has taken on a few Machine Learning projects. He had brief stints in the automobile and education sector before venturing into Tech. He is a firm believer in out-of-school learning and he considers technology a vital tool for delivering effective education. He relishes every opportunity to teach.',
          imgURL: 'person.png',
          linkdinURL: '',
          twitterURL: ''
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
</style>